<h1 style="font-family: Arial, sans-serif; margin-left: 20px;">GitLab .gitlab-ci.yml example file.&nbsp;&nbsp; Download: <a rel="noopener" target="_blank" alt=".gitlab-ci.yml" href='https://gittdownloads.s3.us-east-1.amazonaws.com/.gitlab-ci.yml'>.gitlab-ci.yml</a></h1>

<div class="editor">
  <span class="comment"># This pipeline creates a release script from the Git repository.</span><br>
  <span class="comment"># Restores the database backup taken right after our last release to production.</span><br>
  <span class="comment"># Applies the release script to the database - any error will fail this pipeline.</span><br>
  <span class="comment"># Finaly, it will back up the updated database and place it in an S3 bucket for testing.</span><br><br>

  <span class="comment"># Project-level variables</span><br>
    <span class="comment"># AWS_DEFAULT_REGION</span><br><br>

    <span class="comment"># Masked project-level variables</span><br>
    <span class="comment"># AWS_ACCESS_KEY_ID</span><br>
    <span class="comment"># AWS_SECRET_ACCESS_KEY</span><br>
    <span class="comment"># GIT_TOOLSET_LICENSE</span><br><br>



    <span class="key">workflow</span>:<br>
    &nbsp;&nbsp;<span class="key">rules</span>:<br>
    &nbsp;&nbsp;<span class="key">- if</span>: <span class="string">'$CI_PIPELINE_SOURCE == "merge_request_event" && $CI_MERGE_REQUEST_TARGET_BRANCH_NAME == $CI_DEFAULT_BRANCH'</span><br>
    &nbsp;&nbsp;&nbsp;&nbsp;<span class="key">when</span>: <span class="string">always</span><br> 
    &nbsp;&nbsp;<span class="key">- when</span>: <span class="string">never</span>
    <br>
    <br>

    <span class="key">default</span>:<br>
    &nbsp;&nbsp;<span class="key">image</span>: <span class="string">digitaltools/gittoolset:latest</span>&nbsp;&nbsp;&nbsp;<span class="comment"># gitt, aws, zip and unzip installed</span><br>
    &nbsp;&nbsp;<span class="key">interruptible</span>: <span class="string">true</span><br><br>

    <span class="key">stages</span>:<br>
    &nbsp;&nbsp;- <span class="string">build</span><br>
    &nbsp;&nbsp;- <span class="string">apply</span><br>
    &nbsp;&nbsp;- <span class="string">backup</span><br><br>

    <span class="key">variables</span>:<br>
    &nbsp;&nbsp;<span class="key">GIT_DEPTH</span>: <span class="string">0</span>&nbsp;&nbsp; <span class="comment"># important to allow gitt to find start commit in history</span><br>
    &nbsp;&nbsp;<span class="key">PROJECT_NAME</span>: <span class="string">"Northwind"</span><br>
    &nbsp;&nbsp;<span class="key">DATABASE_DIR</span>: <span class="string">"Database"</span><br>
    &nbsp;&nbsp;<span class="key">LAST_RELEASE_DB_BACKUP_FILE_NAME</span>: <span class="string">"${{ '{' }}PROJECT_NAME{{ '}' }}_DB_Last_Release"</span><br><br>


    <span class="key">.common-db-job</span>:<br>
    &nbsp;&nbsp;<span class="key">rules</span>:<br>
    &nbsp;&nbsp;&nbsp;&nbsp;- <span class="key">changes</span>:<br>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="key">paths</span>:<br>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- <span class="string">$DATABASE_DIR/**/*</span><br>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="key">when</span>: <span class="string">on_success</span><br>
    &nbsp;&nbsp;&nbsp;&nbsp;- <span class="key">when</span>: <span class="string">never</span><br><br>

    <span class="comment"># Create a release script file containing all changes since the last release.</span><br>
    <span class="key">create-release-script-job</span>:<br>
    &nbsp;&nbsp;<span class="key">extends</span>: <span class="string">.common-db-job</span><br>
    &nbsp;&nbsp;<span class="key">stage</span>: <span class="string">build</span><br>
    &nbsp;&nbsp;<span class="key">variables</span>:<br>
    &nbsp;&nbsp;&nbsp;&nbsp;<span class="key">GIT_TOOLSET_CLI </span>: <span class="string">"gitt"</span><br>
    &nbsp;&nbsp;&nbsp;&nbsp;<span class="key">METADATA_SCRIPTS_DIR</span>: <span class="string">"$DATABASE_DIR/GitToolset/MetadataScripts"</span><br>
    &nbsp;&nbsp;&nbsp;&nbsp;<span class="key">GIT_TOOLSET_PARAMS</span>: &gt;- <br>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="string">--repo-path . <br>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--scripts-path Database <br>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--start-after-tag-containing release_v <br>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--metadata-scripts-dir $METADATA_SCRIPTS_DIR <br>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;--license $GIT_TOOLSET_LICENSE</span><br>
   
&nbsp;&nbsp;&nbsp;&nbsp;<span class="key">BUILD_CMD</span>: <span class="string">"$GIT_TOOLSET_CLI Build $GIT_TOOLSET_PARAMS"</span>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="key">REPORT_CMD</span>: <span class="string">"$GIT_TOOLSET_CLI Log $GIT_TOOLSET_PARAMS --one-line true"</span>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="key">RELEASE_SCRIPT_FILE_NAME</span>: <span class="string">"ReleaseScript"</span>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="key">REPORT_FILE_NAME</span>: <span class="string">"CommitLog"</span>
&nbsp;&nbsp;<span class="key">script</span>:
&nbsp;&nbsp;&nbsp;&nbsp;- <span class="string">echo "Merge $CI_PIPELINE_SOURCE into $CI_MERGE_REQUEST_TARGET_BRANCH_NAME"</span>
&nbsp;&nbsp;&nbsp;&nbsp;- <span class="string">$GIT_TOOLSET_CLI --version</span>
&nbsp;&nbsp;&nbsp;&nbsp;- <span class="string">CURRENT_DATETIME=$(date +"%Y_%m_%d_%H_%M_%S")</span>
&nbsp;&nbsp;&nbsp;&nbsp;- <span class="string">RELEASE_SCRIPT_FILE_NAME_STAMPED="${{ '{' }}RELEASE_SCRIPT_FILE_NAME{{ '}' }}_${{ '{' }}CURRENT_DATETIME{{ '}' }}_${{ '{' }}CI_COMMIT_SHORT_SHA{{ '}' }}_${{ '{' }}CI_PIPELINE_ID{{ '}' }}.sql"</span>
&nbsp;&nbsp;&nbsp;&nbsp;- <span class="string">REPORT_FILE_NAME_STAMPED="${{ '{' }}REPORT_FILE_NAME{{ '}' }}_${{ '{' }}CURRENT_DATETIME{{ '}' }}_${{ '{' }}CI_COMMIT_SHORT_SHA{{ '}' }}_${{ '{' }}CI_PIPELINE_ID{{ '}' }}.txt"</span>
&nbsp;&nbsp;&nbsp;&nbsp;- <span class="string">$BUILD_CMD --output-file $RELEASE_SCRIPT_FILE_NAME_STAMPED</span>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="comment"># Next generate a text file with 1 line for each commit that is included in this release.</span>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="comment"># This text file will be uploaded to a SQL Server table in a later job.</span>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="comment"># Log Line Example:</span>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="comment"># &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6d0d68f (tag: v1.9) Updated customer related stored procs</span>
&nbsp;&nbsp;&nbsp;&nbsp;- <span class="string">$REPORT_CMD &gt; $REPORT_FILE_NAME_STAMPED</span>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="comment"># Need the current time in SQL Server format</span>
&nbsp;&nbsp;&nbsp;&nbsp;- <span class="string">SQL_DATE_STRING=$(date +"%Y-%m-%d %H:%M:%S")</span>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="comment"># Add the CI_COMMIT_SHORT_SHA and current time to each line of the commit logs</span>
&nbsp;&nbsp;&nbsp;&nbsp;- <span class="string">sed -i "s/^/$CI_COMMIT_SHORT_SHA|$SQL_DATE_STRING|/" $REPORT_FILE_NAME_STAMPED</span>
&nbsp;&nbsp;&nbsp;&nbsp;- <span class="string">cat $REPORT_FILE_NAME_STAMPED</span>
&nbsp;&nbsp;&nbsp;&nbsp;- <span class="string">echo "RELEASE_SCRIPT_FILE_NAME_STAMPED=$RELEASE_SCRIPT_FILE_NAME_STAMPED" &gt; create-release-script-job.env</span>
&nbsp;&nbsp;&nbsp;&nbsp;- <span class="string">echo "REPORT_FILE_NAME_STAMPED=$REPORT_FILE_NAME_STAMPED" &gt;&gt; create-release-script-job.env</span>
&nbsp;&nbsp;&nbsp;&nbsp;- <span class="string">echo "CURRENT_DATETIME=${{ '{' }}CURRENT_DATETIME{{ '}' }}" &gt;&gt; create-release-script-job.env</span>
&nbsp;&nbsp;&nbsp;&nbsp;- <span class="string">echo cat create-release-script-job.env</span>
&nbsp;&nbsp;&nbsp;&nbsp;- <span class="string">cat create-release-script-job.env</span>
&nbsp;&nbsp;<span class="key">artifacts</span>:
&nbsp;&nbsp;&nbsp;&nbsp;<span class="key">paths</span>:
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- <span class="string">$RELEASE_SCRIPT_FILE_NAME*.sql</span>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- <span class="string">$REPORT_FILE_NAME*.txt</span>
&nbsp;&nbsp;&nbsp;&nbsp;<span class="key">reports</span>:
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="key">dotenv</span>: <span class="string">create-release-script-job.env</span>
<br>
<br>
<br>
<span class="comment"># copy down the backup of the database created right after the last release to production</span><br>
<span class="key">get-last-release-db-backup-file-job</span>:
&nbsp;&nbsp;<span class="key">extends</span>: <span class="string">.common-db-job</span>
&nbsp;&nbsp;<span class="key">stage</span>: <span class="string"> build</span>
&nbsp;&nbsp;<span class="key">variables</span>:
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="key">LAST_RELEASE_DB_BACKUP_BUCKET: "s3://digitaltools-dbs/databasebackups/releases"
&nbsp;&nbsp;<span class="key">script</span>:
&nbsp;&nbsp;&nbsp;&nbsp;- <span class="string">aws s3 cp ${{ '{' }}LAST_RELEASE_DB_BACKUP_BUCKET{{ '}' }}/${{ '{' }}LAST_RELEASE_DB_BACKUP_FILE_NAME{{ '}' }}.zip .</span>
&nbsp;&nbsp;&nbsp;&nbsp;- <span class="string">unzip ${{ '{' }}LAST_RELEASE_DB_BACKUP_FILE_NAME{{ '}' }}.zip</span>
&nbsp;&nbsp;<span class="key">artifacts</span>:
&nbsp;&nbsp;&nbsp;&nbsp;<span class="key">paths</span>:
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- <span class="string">$LAST_RELEASE_DB_BACKUP_FILE_NAME.bak</span><br>
  <br><br><br>

  
  <span class="comment"># restore backup and apply the release script. Any errors will fail the pipeline.</span><br>
  <span class="key">create-updated-db-job</span>:
&nbsp;&nbsp;<span class="key">extends</span>: <span class="string">.common-db-job</span>
&nbsp;&nbsp;<span class="key">stage</span>: <span class="string"> apply</span>
&nbsp;&nbsp;<span class="key">image</span>:&nbsp;<span class="string">mcr.microsoft.com/mssql/server:2017-latest</span>
&nbsp;&nbsp;<span class="key">services</span>:
&nbsp;&nbsp;&nbsp;&nbsp;- <span class="key">name</span>:&nbsp;<span class="string"> mcr.microsoft.com/mssql/server:2017-latest</span>
&nbsp;&nbsp;<span class="key">alias</span>:&nbsp;<span class="string">sqlserver</span>
&nbsp;&nbsp;<span class="key">needs</span>:
&nbsp;&nbsp;&nbsp;&nbsp;- <span class="string">create-release-script-job</span>
&nbsp;&nbsp;&nbsp;&nbsp;- <span class="string">get-last-release-db-backup-file-job</span>
&nbsp;&nbsp;<span class="key">variables</span>:
&nbsp;&nbsp;&nbsp;&nbsp;<span class="key">MSSQL_HOST</span>:&nbsp;<span class="string">sqlserver</span>
&nbsp;&nbsp;&nbsp;&nbsp;<span class="key">ACCEPT_EULA</span>:&nbsp;<span class="string"> "Y"</span>
&nbsp;&nbsp;&nbsp;&nbsp;<span class="key">MSSQL_PID</span>:&nbsp;<span class="string"> Express</span>
&nbsp;&nbsp;&nbsp;&nbsp;<span class="key">SQL_SERVER_USER</span>:&nbsp;<span class="string"> "sa"</span>
&nbsp;&nbsp;&nbsp;&nbsp;<span class="key">SA_PASSWORD</span>:&nbsp;<span class="string"> "ATempStrong!Passw0rd"</span>
&nbsp;&nbsp;&nbsp;&nbsp;<span class="key">SQLCMD</span>:&nbsp;<span class="string"> "/opt/mssql-tools/bin/sqlcmd -b -e -S sqlserver -U $SQL_SERVER_USER -P $SA_PASSWORD"</span>
&nbsp;&nbsp;&nbsp;&nbsp;<span class="key">PROD_DB_NAME</span>:&nbsp;<span class="string">Northwind</span>
&nbsp;&nbsp;&nbsp;&nbsp;<span class="key">TEST_DB_NAME</span>:&nbsp;<span class="string"> "${{ '{' }}PROD_DB_NAME{{ '}' }}_${{ '{' }}CURRENT_DATETIME{{ '}' }}_${{ '{' }}CI_COMMIT_SHORT_SHA{{ '}' }}_${{ '{' }}CI_PIPELINE_ID{{ '}' }}"</span>
&nbsp;&nbsp;&nbsp;&nbsp;<span class="key">UPDATED_DB_FILE_NAME_STAMPED</span>:&nbsp;<span class="string"> "${{ '{' }}TEST_DB_NAME{{ '}' }}_DB_Updated"</span>
&nbsp;&nbsp;<span class="key">script</span>:
&nbsp;&nbsp;&nbsp;&nbsp;- <span class="string">|</span>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span class="string">$SQLCMD -Q "RESTORE DATABASE [$TEST_DB_NAME] FROM DISK = '$PWD/$LAST_RELEASE_DB_BACKUP_FILE_NAME.bak' \
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;WITH MOVE '$PROD_DB_NAME' TO '$PWD/${{ '{' }}TEST_DB_NAME{{ '}' }}.mdf', MOVE '${{ '{' }}PROD_DB_NAME{{ '}' }}_log' TO '$PWD/${{ '{' }}TEST_DB_NAME{{ '}' }}.ldf'"</span>
&nbsp;&nbsp;&nbsp;&nbsp;- <span class="string">echo "Database restored successfully."</span>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="comment"># Now apply the release script</span>
&nbsp;&nbsp;&nbsp;&nbsp;- <span class="string">echo "Applying release script $RELEASE_SCRIPT_FILE_NAME_STAMPED"</span>
&nbsp;&nbsp;&nbsp;&nbsp;- <span class="string">$SQLCMD -d $TEST_DB_NAME -i ./$RELEASE_SCRIPT_FILE_NAME_STAMPED</span>
&nbsp;&nbsp;&nbsp;&nbsp;- <span class="string">echo "Completed applying release script"</span>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span class="comment"># Run the sqlcmd command to bulk insert the report containing the list of commits in this release.</span>
&nbsp;&nbsp;&nbsp;&nbsp;- <span class="string">|</span>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="string">$SQLCMD -d $TEST_DB_NAME -Q "BULK INSERT CommitLog
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;FROM '$PWD/$REPORT_FILE_NAME_STAMPED'
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;WITH (FIELDTERMINATOR = '|', ROWTERMINATOR = '0x0a', FIRSTROW = 1)"</span>
&nbsp;&nbsp;&nbsp;&nbsp;- <span class="string">echo "Completed uploading report"</span>
&nbsp;&nbsp;&nbsp;&nbsp;- <span class="string">echo "Backing up the database..."</span>
&nbsp;&nbsp;&nbsp;&nbsp;- <span class="string">$SQLCMD -q "BACKUP DATABASE [$TEST_DB_NAME] TO DISK = '$PWD/$UPDATED_DB_FILE_NAME_STAMPED.bak'"</span>
&nbsp;&nbsp;&nbsp;&nbsp;- <span class="string">echo "Backup completed."</span>
&nbsp;&nbsp;&nbsp;&nbsp;- <span class="string">echo "UPDATED_DB_FILE_NAME_STAMPED=$UPDATED_DB_FILE_NAME_STAMPED" &gt; create-updated-db-job.env</span>
&nbsp;&nbsp;&nbsp;&nbsp;- <span class="string">cat create-updated-db-job.env</span>
&nbsp;&nbsp;<span class="key">artifacts</span>:
&nbsp;&nbsp;&nbsp;&nbsp;<span class="key">paths</span>:
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- <span class="string">$UPDATED_DB_FILE_NAME_STAMPED.bak</span>
&nbsp;&nbsp;&nbsp;&nbsp;<span class="key">reports</span>:
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="key">dotenv</span>: <span class="string">create-updated-db-job.env</span>

<br>
<br>
<br>


<span class="key"></span>put-updated-db-backup-file-job</span>:
&nbsp;&nbsp;<span class="key">extends</span>: <span class="string">.common-db-job</span>
&nbsp;&nbsp;<span class="key">stage</span>: <span class="string"> backup</span>
&nbsp;&nbsp;<span class="key">needs</span>:
&nbsp;&nbsp;&nbsp;&nbsp;- <span class="string">create-updated-db-job</span>
&nbsp;&nbsp;<span class="key">variables</span>:
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="string">UPDATES_DB_BACKUP_BUCKET</span>:&nbsp;<span class="string">"s3://digitaltools-dbs/databasebackups/updates"</span>
&nbsp;&nbsp;<span class="key">script</span>:
&nbsp;&nbsp;&nbsp;&nbsp;- <span class="string">zip $UPDATED_DB_FILE_NAME_STAMPED $UPDATED_DB_FILE_NAME_STAMPED.bak</span>
&nbsp;&nbsp;&nbsp;&nbsp;- <span class="string">aws s3 cp ./$UPDATED_DB_FILE_NAME_STAMPED.zip $UPDATES_DB_BACKUP_BUCKET/</span><br><br> 

<br>

<span class="key">dummy-job</span>:
&nbsp;&nbsp;<span class="key">stage</span>: <span class="string">build</span>
&nbsp;&nbsp;<span class="key">script</span>:
&nbsp;&nbsp;&nbsp;&nbsp;- <span class="string">echo "Fixes the stuck on 'Checking Pipeline Status' problem if no other job runs"</span>

    
<br>
<br>

</div>
