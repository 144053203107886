import { Component } from '@angular/core';
import { Video } from '../../models/Video';
import { Router } from '@angular/router';

@Component({
  selector: 'video-list',
  standalone: false,
  templateUrl: './video-list.component.html',
  styleUrl: './video-list.component.scss'
})
export class VideoListComponent {

  videos: Video[] = [
    {
      title: 'Script Out Database Objects',
      description: 'Script out the database objects to be put into a Git repo.',
      thumbnail: 'ScriptDB.png',
      url: 'https://gittdownloads.s3.us-east-1.amazonaws.com/ScriptDB.mp4'
    },
    {
      title: 'Add GitToolset to a Git Repo',
      description: 'Integrate GitToolset into your Git repository.',
      thumbnail: 'GitToolsetUtils.png',
      url: 'https://gittdownloads.s3.us-east-1.amazonaws.com/GitToolsetUtils.mp4'
    },
    {
      title: 'Setup Sort Order',
      description: 'Set the default sort order for directories.',
      thumbnail: 'SortOrder.png',
      url: 'https://gittdownloads.s3.us-east-1.amazonaws.com/SortOrder.mp4'
    },
    {
      title: 'Release Scripts',
      description: 'Basics of working with GitToolset to build release scripts.',
      thumbnail: 'ReleaseScripts.png',
      url: 'https://gittdownloads.s3.us-east-1.amazonaws.com/ReleaseScripts.mp4'
    }
  ];

  
constructor(
  private router: Router  ) {}


  onSelectVideo(video: Video) {
    console.log('Selected video:', video);
    this.router.navigate(['/play-video'] , { state: { url: video.url, title: video.title }
    });

  }
}
