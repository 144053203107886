<h1>Setup MSSQL Databasse Scripts for Git</h1>

<p>You can use MS SQL Server Management Studio to script out a database's objects. Then use Git Toolset's Setup command to fix the output so it is ready for Git.</p>

<br>
<br>
<ol>
    <li>From SQL Server Management Studio, right click your database.</li>
    <li>Select "Tasks"</li>
    <li>Select "Generate Scripts..."</li>
    <li>Select the "Set Scripting Options" tab</li>
    <li>Select "Single file per object"</li>
    <li>Next to "Dirctory Name:" set it to a temporary directory</li>
    <li>Click through the "Next" buttons until it is finished</li>

</ol>

<br>
<br>
<p>Once the export finishes the files will all be in the same directory and the name will be in the format of SchemaName.ObjectName.ObjectType.sql, example: dbo.Customers.Table.sql </p>



<br>
<p>You can use Git Toolset to change this to be ObjectType/SchemaName.ObjectName.sql, example: Tables/dbo.Customers.sql</p>

<br>
<p>The command will be ssomething like:</p>

<pre><code>
    gitt Setup --from-dir .\NorthwindExport --to-dir .\NorthwindFixed --db-type mssql

</code></pre>