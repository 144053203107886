import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GitLabComponent } from '../app/components/git-lab/git-lab.component';
import { AuthGuardService as AuthGuard } from '../app/infrastructure/login/auth-guard.service';
import { OverviewComponent } from '../app/components/overview/overview.component';
import { DocumentationComponent } from '../app/components/documentation/documentation.component';
import { LicensesComponent } from '../app/components/licenses/licenses.component';
import { ContactUsComponent } from '../app/infrastructure//contact-us/contact-us.component';
import { LoginComponent } from '../app/infrastructure//login/login.component';
import { RegisterComponent } from '../app/infrastructure//register/register.component';
import { ForgotPasswordComponent } from '../app/components/forgot-password/forgot-password.component';
import { AboutComponent } from '../app/components/about/about.component';
import { DownloadsComponent } from '../app/components/downloads/downloads.component';
import { MessageComponent } from '../app/infrastructure//message/message.component';
import { LogListComponent } from '../app/infrastructure//logging/log-list.component';
import { ResetPasswordComponent } from '../app/infrastructure//reset-password/reset-password.component';
import { ChangePasswordComponent } from '../app/infrastructure//change-password/change-password.component';
import { MaterialModule } from './material.module';
import { SuccessComponent } from '../app/components/success/success.component';
import { CancelComponent } from '../app/components/cancel/cancel.component';
import { ProductComponent } from '../app/components/product/product.component';
import { CheckoutComponent } from '../app/components/checkout/checkout.component';
import { PrivacyPolicyComponent } from '../app/components/privacy-policy/privacy-policy.component';
import { AppModule } from '../app/app.module';
import { HeyComponent } from '../app/components/hey/hey.component';
import { PlayVideoComponent } from '../app/components/play-video/play-video.component';
import { VideoListComponent } from '../app/components/video-list/video-list.component';


export const routes: Routes = [
{ path: '', component: OverviewComponent,   title: 'Overview'},
{ path: 'home', component: OverviewComponent,   title: 'Overview'},
{ path: 'documentation', component: DocumentationComponent, title: 'Documentation'},
{ path: 'message', component: MessageComponent },
{ path: 'contactUs', component: ContactUsComponent, title: 'Contact Us'},
{ path: 'licenses', component: LicensesComponent, title: 'Licenses', canActivate: [AuthGuard] },
{ path: 'login', component: LoginComponent, title: 'Login'},
{ path: 'register', component: RegisterComponent, title: 'Register'},
{ path: 'forgotPassword', component: ForgotPasswordComponent, title: 'ForgotPassword'},
{ path: 'about', component: AboutComponent, title: 'About'},
{ path: 'downloads', component: DownloadsComponent, title: 'Downloads'},
{ path: 'logs', component: LogListComponent, title: 'Log List' },
{ path: 'resetPassword', component: ResetPasswordComponent, title: 'Reset Password' },
{ path: 'changePassword', component: ChangePasswordComponent, title: 'Change Password' },
{ path: 'product', component: ProductComponent, title: 'gitt' },
{ path: 'success', component: SuccessComponent, title: 'Order Success' },
{ path: 'cancel', component: CancelComponent, title: 'Order Canceled'},
{ path: 'checkout/:type/:quantity', component: CheckoutComponent, title: 'Checkout', canActivate: [AuthGuard] },
{ path: 'checkout', component: CheckoutComponent, title: 'Checkout', canActivate: [AuthGuard] },
{ path: 'privacy-policy', component: PrivacyPolicyComponent },
{ path: 'play-video', component: PlayVideoComponent},
{ path: 'video-list', component: VideoListComponent},
{ path: 'hi', component: GitLabComponent},
{ path: 'hey', component: HeyComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
