import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'play-video',
  standalone: false,
 templateUrl: './play-video.component.html',
  styleUrl: './play-video.component.scss'
})
export class PlayVideoComponent {
  url: string | undefined;
  title: string;

  constructor(private router: Router) {
    // Get the state data
    const state = router.getCurrentNavigation()?.extras?.state;
   
    if(state)
    {

      this.url = state['url'];
      this.title = state['title'];
      console.log(this.url);

    }
  }



}
