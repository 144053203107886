<h1>Command Line Parameters for Git Toolset (gitt)</h1>
<pre>Description:
     Git Toolset (gitt) - searches your Git repository for commits matching your WHERE options 
     (i.e.--start-after-tag-containing "release_v"), it finds all the *.sql files changed in those commits. 

     You can use the Build command to create a single release script file or the Query command to list the files names.

Usage:
  gitt [command] [options]

Commands:
  B, Build                 Git Toolset (gitt) - searches your Git repository for commits matching your WHERE options
                           (i.e. --start-after-tag-containing "release_v"), it extracts all the *.sql files changed in
                           those commits, organizes them according to customizable sorting rules (with optional
                           per-script overrides). It then optionally adds relevant metadata and compiles the scripts
                           into a single, well-structured SQL release file.
  Q, Query                 Query a Git repository for commits matching your WHERE options and prints the file names.
  SHAList                  Query a Git repository and prints a list of SHAs matching your WHERE options.
  L, Log                   List the commits that match the commit WHERE options.
  AddLicense               Add and save the license key to this gitt installed appsettings.json config file. The key
                           should look something like: .e29d6963f43a4416bb74860ac006bc84.725760.1.1.a555f.
  SortOrder                List the directories in order that they sort based on sort files (gitt_*.) Sorts are alpha
                           numeric are case sensitive. Option -v will show the value used to determine the sort order.
  OutputName               Get the output file name after applying the current timestamp.
  Setup                    Change the Generate Scripts output directory structure to be DatabaseObjectType/Schma.ScriptFile.sql


Options:
  -r, --repo-path                       Local path to the root of the Git repo containing the .sql files.
  -s, --scripts-path                    Within the Git repo, the relative path to the root directory that containing
                                        the .sql script files or directories. The default is an empty string, so the
                                        repo's root directory.
  -l, --license                         Your license key.
  -o, --output-file                     Path and file name for the Build command's output file. If you set this to
                                        something like "/output/ReleaseScript_&#123;yyyyMMdd_HHmm&#125;.sql" it will be valued
                                        with the current date and time like "/output/ReleaseScript_20240316_1522.sql".
                                        Using &#123;yyMMddHHmmssfffffff&#125; Using &#123;yyMMddHHmmssfffffff&#125; will generate a unique
                                        name.
  -m, --metadata-scripts-dir            (Optional) Path to metadata script files to include while building the release
                                        script. The scripts contain metadata placeholders for script level headers and
                                        footers. Samples can be found in the directory where gitt is installed.
                                        This directory will be excluded from normal *.sql processing by gitt.
  --start-tag                           Process commits starting with the commit with this tag.
  --start-after-tag                     Process commits starting after the one with this tag.
  --start-tag-containing                Process commits starting with the with a tag containing this text.
  --start-after-tag-containing          Process commits starting after the one with this tag containing this text.
  --start-tag-message-containing        Start processing commits with the one with a tag message containing this text.
  --start-after-tag-message-containing  Start processing commits after the one with a tag message containing this text.
  --start-sha                           Process commits starting with the one with this SHA. SHA must be 7 char or
                                        longer.
  --start-time                          Process commits starting with a commit date equal to or after this start time.
                                        Example: "10/25/2026 14:30". Uses the UTC option.
  --end-tag                             Stop processing commits with the one with this tag.
  --end-before-tag                      Stop processing commits with the on before the commit with this tag.
  --end-tag-containing                  Stop processing commits with the one with a tag containing this text.
  --end-before-tag-containing           Stop processing commits before the one with a tag containing this text.
  --end-tag-message-containing          Stop processing commits on the one with a tag message containing this text.
  --end-before-tag-message-containing   Stop processing commits before the one with a tag containing this text.
  --end-sha                             Stop processing commits with the one with this SHA. SHA must be 7 char or
                                        longer.
  --end-time                            Stop processing commits up until this commit time. Example: "10/25/2026 14:30".
                                        Uses the UTC option.
  -u, --untracked                       Allow for untracked files to be included. Default it True.
  --env                                 Add environment variables to the config values. The default is False.
                                        The environment variable for --license would be License and --start-tag would be StartTag, etc.
  -c, --config                          One or more config json files to override values in other config files.
                                        Overrides all values defined except command line parameters and environment
                                        variables.
  --utc                                 Use UTC time. If not set it will use the time of the computer gitt is running
                                        on. The default is False.
  -a, --author                          Include only commits where the author made changes. (Good for code reviews.)
  --not-author                          Include only files where this author did not make any changes.
  --only-author                         Include only files where this author is the only one to make changes.  (Good
                                        for code reviews.)
  --overlap-author                      Include only files where this author made changes and so did someone else.
  --include-directories-file            Path to a text file containing the names of additional directories to include
                                        other than the default directories. The names may be case sensitive.
  --exclude-directories-file            Path to a text file that containing the names of the directories to exclude.
                                        Default is no directories. The names may be case sensitive.
  --exclude-shas-file                   Path to a text file containing the commit SHAs to exclude that would have been
                                        included otherwise. One SHA per line, 7 char minimum.
  --include-shas-file                   Path to a text file containing the commit SHAs to include that would not be
                                        included otherwise. One SHA per line, 7 char minimum. If not specified, all
                                        commits between start and end are included.
  --extension                           Include only files with a certain extension. The default is ".sql"  This is not
                                        case sensitive. Use * to include all extensions.
  -x, --exclude-scripts                 Exclude the contents of the script files. This would just populate the
                                        header/footer metadata with placeholder values.
  -d, --debug                           Print out debug information.
  -w, --write-out                       Write the release scripts contents to the console.
  -?, -h, --help                        Show help and usage information


Notes:
    All of these option can have a default setting in the appsettings.json file found where gitt was installed.

    Values from command line parameters override options set in appsettings.json and environment variables.

    It first finds the commit matching the end option in the repo's list of commits sorted from newest to oldest. 
    It then finds the first start commit matching you start option that is older than the end commit.

    In a pipeline you can use something like "start-after-tag-containing 'release_v'" - the Git commit tag for your last release to production.

    Set zero or one Start option from:
        start-tag
        start-tag-containing
        start-after-tag-containing
        start-tag-message-containing
        start-after-tag-message-containing
        start-sha
        start-time

    If a Start option isn't set, it will process every commit from the start of the current branch.

    Set zero or one End parameter from:
        end-tag
        end-before-tag
        end-tag-containing
        end-before-tag-containing
        end-tag-message-containing
        end-before-tag-message-containing
        end-sha
        end-time

    If you don't set an End option it will process till the end (HEAD).

    There is also an option [--untracked, -u] that cause the process to include untracked</pre>

    <br>
    <br>
    <br>