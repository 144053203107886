<nav id="main-menu">
  <ul>
    <li class="logo-container">
      <a href="" alt="logo"><img id="logo" class="logo" alt="Git Toolset Logo" src="Logo.png" />
      </a>
      <span>Git Toolset</span>
    </li>
    <li>
      <div class="menu flex-container" [class.is-open]="isMenuOpen">
        <button mat-button class="menu-btn" routerLink="" routerLinkActive="active">Overview</button>
        <button mat-button class="menu-btn" routerLink="/product" routerLinkActive="active">Try/Buy</button>
        <button mat-button class="menu-btn" routerLink="/downloads" routerLinkActive="active">Downloads</button>
        <button mat-button class="menu-btn" routerLink="/documentation" routerLinkActive="active">Documentation</button>

        <button mat-button class="menu-btn support-trigger" [matMenuTriggerFor]="menuD">Support</button>
        <button mat-button class="menu-btn support-button" (click)="onSupport()">Support</button>

        <mat-menu class='sub-menu-full' #menuD="matMenu">
          <button mat-menu-item class="sub-menu-btn" (click)="onLicense()" routerLinkActive="active">Licenses</button>
          <button mat-menu-item class="sub-menu-btn" routerLink="/video-list" routerLinkActive="active">Videos</button>
          
          <button mat-menu-item class="sub-menu-btn" routerLink="/contactUs" routerLinkActive="active">Contact
            Us</button>
          <button mat-menu-item class="sub-menu-btn" routerLink="/privacy-policy" routerLinkActive="active">Privacy
            Policy</button>
            <button mat-menu-item class="sub-menu-btn" routerLink="/about" routerLinkActive="active">About</button>
          </mat-menu>
        <div class="flex-container">
          <a class="login" *ngIf='!(authService.isLoggedIn$ | async)' routerLink="/register" routerLinkActive="active"
            ariaCurrentWhenActive="page">Register</a>
          <span class="login"
            *ngIf='(authService.isLoggedIn$ | async)'>{{authService.user.email}}&nbsp;&nbsp;</span>
          <a class="login" *ngIf='!(authService.isLoggedIn$ | async)' routerLink="/login" routerLinkActive="active"
            ariaCurrentWhenActive="page">Login</a>
          <a class="login" *ngIf='(authService.isLoggedIn$ | async)' href="javascript:void(0);"
            (click)="onLogoutClick()">Log Out</a>
        </div>
      </div>
      <div class="sub-menu-small flex-container" [class.is-sub-open]="isSubMenuOpen">
        <button mat-menu-item class="sub-menu-btn" (click)="onLicense()" routerLinkActive="active">Licenses</button>
        <button mat-menu-item class="sub-menu-btn" routerLink="/about" routerLinkActive="active">About</button>
        <button mat-menu-item class="sub-menu-btn" routerLink="/contactUs" routerLinkActive="active">Contact Us</button>
        <button mat-menu-item class="sub-menu-btn" routerLink="/privacy-policy" routerLinkActive="active">Privacy
          Policy</button>
      </div>
    </li>
    <li class="burger" (click)="toggleMenu()">
      <i><mat-icon>{{ isMenuOpen || isSubMenuOpen ? "close" : "menu" }}</mat-icon></i>
    </li>
  </ul>

</nav>