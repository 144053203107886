    <h1>Automating SQL Change Releases Using Git Toolset - Database DevOps</h1>

    <br>
    <h2>Authoring Change Scripts</h2>
    <p>The strategy is to ensure that change scripts are idempotent, meaning they can be run multiple times on the same database without errors. For example:</p>
    <pre><code>IF NOT EXISTS (SELECT *
           FROM INFORMATION_SCHEMA.COLUMNS
           WHERE TABLE_NAME = 'Customers'
           AND COLUMN_NAME = 'Email')
BEGIN
    ALTER TABLE Customers 
    ADD Email NVARCHAR(256);
END
</code></pre>
<br>
Same idea when you add system data:
<pre><code>IF (NOT EXISTS (SELECT * 
    FROM dbo.States 
    WHERE Abbreviation = 'AL'))
BEGIN

    INSERT INTO States
    (Abbreviation, Name)
    VALUES ('AL', 'Alabama'),
           ('AK', 'Alaska'),
           ...
END
</code></pre>
<br>

    <h2>Script File Naming</h2>
    <p>Change scripts are SQL updates saved in a file, with the file name corresponding to the database object it creates or modifies. For instance, the script above would be saved as <code>Customers.sql</code>. Files are organized into folders based on the database object type, so the file would be stored as <code>Tables\Customers.sql</code>.</p>

    <h2>Script History</h2>
    <p>Each time you overwrite a script (like <code>Customers.sql</code>), Git tracks the file's change history.</p>

    <img src='History.png' alt='history'  class='center' />
<br>


<br>
    <h2>Building a Release Script</h2>
    <p>To generate a release script containing all changes since the last release, you would use something like:</p>
    <pre><code>gitt Build --start-after-tag-containing "release-v"
</code></pre>
    <p>(Note: <code>gitt</code> refers to Git Toolset, not Git.)</p>
    This produces a file named something like <pre><code>MyProject_ReleaseScript_20240907T1845396398702.sql</code></pre> 
    This file contains all of the SQL change scripts committed to Git since the last release and it is used to update the database to the next version.
    <p></p>
    
    <h2>How Does It Work?</h2>
    <p>Here is a sample git log:</p>

    <img src='GitLog.png' alt='Git Log' class='center' />
        <ol>
            <li>Since the command above doesn't include an end filter like --end-tag or --end-sha the filtering process will start with HEAD.</li>
            <li>It will then find the start log by looking for --start-after-tag-containing "release-v". So in this example would stop at the 4th commit down.</li>
            <li>It then extract all of the changed <code>*.sql</code> files for the commits between <span style="color: #C19C00; font-weight: bold;">c04cc3f</span> and <span style="color:#61D6D6; font-weight: bold">HEAD</span>.</li>
            <li>During setup the directories were assigned a sort order, but developers can easily override the sort order for an individual file.</li>
            <li>It retrieves your metadata template files (if any), that contain scripts for the start / end of the release script, and before / after each included script file. They contain placeholders like %f for file name, %cs commit hash, and %ca for commit author, these will be populated during the build.</li>
            <li>Next it values your output file template (like: "\output\MyProject_yyyyMMddHHmmssttt.sql") to create the output file name.</li>
            <li>It then builds the single release script file.</li>
        </ol>
    
    
    <br>
    Download sample release script file: <a rel="noopener" target="_blank" alt="MyProject_20241227T20584109133.sql" href='https://gittdownloads.s3.us-east-1.amazonaws.com/MyProject_20241227T1658214109133.sql'>MyProject_20241227T20584109133.sql</a>
<br>
<br>
    <h2>Git Repository Structure</h2>
    <p>While database scripts can be stored in a separate repository, we recommend keeping them in the same repository as your front-end code. For example:</p>
<ul>
<li>📁 MyProject
	<ul>
    	<li>📁 Code</li>
    	<li>📂 Database
        	<ul>
            	<li>📁 Functions</li>
            	<li>📁 Security</li>
            	<li>📁 Stored Procedures</li>
            	<li>📁 Tables</li>
            	<li>📁 Views</li>
        	</ul>
    	</li>
	</ul>
</li>
</ul>
<p>This setup ensures that changes to both the database and front-end (e.g., adding an <code>Email</code> column in the <code>Customers</code> table and updating the customer edit page) are included in the same commit. This improves traceability and simplifies operations like cherry-picking, reverting commits or seeing a complete picture when you view commit details.</p>
<br>


<br>
<h2>Benefits</h2>
<ul class="bullets">
    <li>Using the exact same release script across QA, UAT, and production environments is highly beneficial for ensuring consistency, valid testing, and minimizing errors.</li>
    <li>No manual processes required to create a release.</li>
    <li>Lightweight system that aligns with how code is managed.</li>
    <li>Your Git repository logs serve as the system of record for code and database changes.</li>
    <li>Seamlessly integrates with CI/CD pipelines.</li>
    <li>Preinstalled on a Docker image, available for download from <a href='https://hub.docker.com/r/digitaltools/gittoolset' alt='Docker Hub'>DockerHub</a>.</li>
    <li>One-time license fee—no recurring subscription.</li>
</ul>
 
<br>
<br>

 


    <div class="quote">
        <blockquote>
            <p>
                As a result every database refactoring is automated by writing it in the form of SQL DDL (for the schema change) and DML (for the data migration). These changes are never applied manually...
                <br>
                <br>...run the migration scripts on the mainline copy of the database, and then run all the application tests. If all is green, this process will be repeated across the whole Deployment Pipeline, including QA and Staging environments. The same code finally is run against production, now updating the live database's schema and data.
            </p>
                -- <a href="https://en.wikipedia.org/wiki/Martin_Fowler">Martin Fowler</a> and <a href="https://www.nofluffjuststuff.com/conference/speaker/pramod_sadalage">Pramod Sadalage</a>
                &nbsp;&nbsp;<cite title="Evolutionary Database Design"><a href="http://martinfowler.com/articles/evodb.html" alt="Book" target="_blank">Evolutionary Database Design</a></cite>
        </blockquote>
    
    </div>

    <br>
    <br>

    <h2>Next Steps</h2>
    <ul>
       <!-- <li><a href="#">Watch the video</a></li>-->
       <li>Review <a href="/documentation">Documentation</a></li>
        <li>Obtain a license from <a href="/product">Product</a></li>
        <li>Download and install Git Toolset from <a href="/downloads">Download Git Toolset</a></li>
    </ul>
<br><br><br>

