<div class="video-list">
    <mat-card *ngFor="let video of videos" class="video-card">
      <mat-card-header>
        <mat-card-title>{{ video.title }}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="video-thumbnail">
          <img mat-card-image [src]="video.thumbnail" alt="{{ video.title }}">
        </div>
        <p>{{ video.description }}</p>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button (click)="onSelectVideo(video)">Select</button>
      </mat-card-actions>
    </mat-card>
  </div>
  